<template>
  <keep-alive>
    <component v-if="loadPage" :is="loadPage" :appId="appId"></component>
    <div v-else>
      <h1>404</h1>
    </div>
  </keep-alive>
</template>

<script>
import { useStore } from "vuex";
import externalComponent from "../../modules/externalComponent";

export default {
  name: "NotFound",
  data() {
    const store = useStore();

    return {
      store: store,
      appId: null,
      loadPage: null,
    };
  },
  computed: {
    path() {
      return this.$route.path;
    },
    apps() {
      return this.store.state.apps;
    },
  },

  watch: {
    apps: {
      deep: true,
      handler() {
        this.loadAppsPage();
      },
    },
    path() {
      this.loadAppsPage();
    },
  },
  mounted() {
    this.loadAppsPage();
  },
  methods: {
    loadAppsPage() {
      for (const app of this.apps) {
        if (app.pages === undefined) {
          continue;
        }
        const page = app.pages.find((x) => x.path === this.path);
        if (page) {
          externalComponent(`/${app.name}/page/${page.name}.umd.min.js`).then(
            (MyComponent) => {
              this.appId = app.id;
              this.loadPage = MyComponent;
            }
          );
          break;
        }
      }
    },
  },
};
</script>
